/**
 * Global routes table
 */
export const ROUTES = {
  // Apply
  APPLY: "/apply",
  CERTIFY: "/certify",
  GROW: "/grow",

  // Auth
  AUTH_LOGIN: "/",
  AUTH_FORGOT_PASSWORD: "/auth/forgot-password",
  AUTH_RESET_PASSWORD: "/auth/forgot-password-reset",
  AUTH_VERIFY_CODE: "/auth/verify-code",
  AUTH_VERIFY_EMAIL: "/auth/verify-email",

  AUTH_CREATE_ACCOUNT: "/register",
  REGISTER_PAYMENT: "/register/payment",
  REGISTER_PLAN_SELECTION: "/register/plan-selection",

  // Music care
  DASHBOARD: "/dashboard",
  CUSTOMERS_ROOT: "/customers",
  CUSTOMERS: "/customers",
  CUSTOMERS_CREATE: "/customers/create",
  CUSTOMERS_VIEW_SINGLE: "/customers/view",
  TRAIN: "/train",

  // Admin
  ADMIN_VIEW_USER: "/admin/view/",
  ADMIN: "/admin/",
  MEETING: "/meetings",
  MATERIALS: "/materials",
  SESSIONS: "/admin/sessions",
  ASSESSOR_MATERIALS: "/assessor-materials",
  FACILITATOR_MATERIALS: "/facilitator-materials",
  COURSE_CODES: "/course-codes",
  ASSIGN_CERTIFY_FACULTY: "/assign-certify-faculty",

  // Org cert
  ORG_CERT_PLAN: "/organization/plan",
  ORG_CERT_DRIVER: "/organization/driver",
  ORG_CERT_PORTFOLIO: "/organization/portfolio",
  ORG_CERT_ENGAGE: "/organization/engage",
  ORG_CERT_ENGAGE_LTD: "/organization/engageLimited",
  ORG_CERT_REGISTRY: "/organization/orgRegistry",
  ORG_CERT_GROW: "/organization/grow",

  // Misc
  UNAUTHORIZED: "/unauthorized",
  PROFILE: "/profile",
  TEST_EDITOR: "/test-editor",
};
