import { Box, Button, FormHelperText, Link as MuiLink } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import Link from "next/link";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";
import { useResendConfirmationEmailMutation } from "queries/useResendConfirmationEmail";
import type { FC } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { FormikTextField } from "components/formik/FormikTextField";

import { ROUTES } from "constants/routes";
import useAuth from "hooks/useAuth";
import useIsMountedRef from "hooks/useIsMountedRef";

export interface LoginFormState {
  email: string;
  password: string;
  submit?: string;
}

const initialValues: LoginFormState = {
  email: "",
  password: "",
};

export const LoginForm: FC = (props) => {
  const isMountedRef = useIsMountedRef();
  const { i18n } = useTranslation();
  const { login } = useAuth();
  const router = useRouter();

  const [resendEmail, setResendEmail] = React.useState("");
  const [showResendEmail, setShowResendEmail] = React.useState(false);
  const [resendEmailConfirmation] = useResendConfirmationEmailMutation();
  const { enqueueSnackbar } = useSnackbar();

  const loginFormSchema: Yup.SchemaOf<LoginFormState> = Yup.object({
    email: Yup.string().required(i18n.t("login.email.error")),
    password: Yup.string().required(i18n.t("login.password.error")),
    submit: Yup.string(),
  });

  const handleSubmitLogin = async (
    values: LoginFormState,
    { setErrors, setSubmitting }: FormikHelpers<LoginFormState>
  ) => {
    try {
      await login({
        email: values.email,
        password: values.password,
      });

      if (isMountedRef.current) {
        router.push(ROUTES.DASHBOARD);
        setSubmitting(false);
      }
    } catch (err: unknown) {
      if (isMountedRef.current) {
        setErrors({ submit: (err as Error).message });
        setResendEmail(values.email);
        if (
          (err as Error).message ===
          "You must confirm your email before logging in"
        )
          setShowResendEmail(true);
        setSubmitting(false);
      }
    }
  };

  const tryResendConfirmationEmail = async () => {
    if (resendEmail)
      try {
        await resendEmailConfirmation({
          variables: { email: resendEmail },
        });
        enqueueSnackbar("Resent!", { variant: "success" });
      } catch (err: unknown) {
        enqueueSnackbar("An Error Occurred", { variant: "error" });
      }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={loginFormSchema}
      onSubmit={handleSubmitLogin}
    >
      {({ errors, isSubmitting }) => (
        <Form {...props}>
          <FormikTextField
            name="email"
            autoFocus
            autoComplete="username"
            fullWidth
            label={i18n.t("login.email.useremail")}
            margin="normal"
            type="username"
          />

          <FormikTextField
            name="password"
            autoComplete="password"
            fullWidth
            label={i18n.t("login.password.label")}
            margin="normal"
            type="password"
          />

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Link href={ROUTES.AUTH_FORGOT_PASSWORD} passHref>
              <MuiLink sx={{ pt: 1 }}>
                {i18n.t("login.forgot_password")}
              </MuiLink>
            </Link>
          </Box>

          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          {showResendEmail ? (
            <Box sx={{ mt: 2 }}>
              <Button
                color="secondary"
                fullWidth
                size="large"
                variant="contained"
                onClick={() => {
                  tryResendConfirmationEmail();
                }}
              >
                Resend my verification email
              </Button>
            </Box>
          ) : null}

          <Box sx={{ mt: 2 }}>
            <Button
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="secondary"
            >
              {i18n.t("login.loginbutton")}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
