import { Box, Card, CardContent, Container, useTheme } from "@mui/material";
import Image from "next/image";
import type { FC } from "react";

export const AuthFormWrapper: FC<{ title: string }> = ({ title, children }) => {
  const theme = useTheme();
  return (
    <>
      <title>
        {title} | <b>MUSIC CARE</b>
      </title>

      <Box
        sx={{
          backgroundColor: "background.login",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="sm" sx={{ py: 10 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pb: 4,
            }}
          >
            <Image
              alt="FL Logo"
              src={
                theme.palette.mode === "light"
                  ? "/logos/music-care-block.png"
                  : "/logos/music-care-block-white.png"
              }
              width={185}
              height={75}
            />
          </Box>

          <Card
            sx={{
              backgroundColor: "background.login",
              boxShadow: "none",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 3,
              }}
            >
              {children}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};
